<template>
  <div class="configure_I">
    <div class="headNavs">
      <div>
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect_top">
          <el-menu-item index="1">魔豆会员</el-menu-item>
          <el-menu-item index="2">魔豆合伙人</el-menu-item>
          <el-menu-item index="3">企业会员</el-menu-item>
          <el-menu-item index="4">会员权益</el-menu-item>
          <el-menu-item index="5">发现页</el-menu-item>
        </el-menu>
      </div>
      <div class="headDetails">平台会员用户会员配置</div>
    </div>

    <div class="TablesList">
      <div class="TablesADD">
        <div class="NavLiQuery" @click="headerEdit()"><i class="el-icon-plus"></i>编辑</div>
      </div>
      <div v-if="isShow == 1">
        <div class="ToConfigure" style="border-top: 1px solid #eee;">
          <div>会员卡价格</div>
          <div>{{viplist.threshold}}</div>
        </div>
        <div class="ToConfigure">
          <div>会员卡原价</div>
          <div>{{viplist.intro}}</div>
        </div>
        <div class="ToConfigure">
          <div>消费门槛（元）</div>
          <div>{{viplist.price}}</div>
        </div>
        <div class="ToConfigure">
          <div>简介</div>
          <div>{{viplist.line_price}}</div>
        </div>
        <div class="ToConfigure">
          <div>详情</div>
          <div class="details">{{viplist.content}}</div>
        </div>
      </div>
      <div v-if="isShow == 2">
        <div class="ToConfigure" style="border-top: 1px solid #eee;">
          <div>消费门槛（元）</div>
          <div>{{partnerlist.threshold}}</div>
        </div>
        <div class="ToConfigure">
          <div>简介</div>
          <div class="details">{{partnerlist.intro}}</div>
        </div>
      </div>
      <div v-if="isShow == 3">
        <div class="ToConfigure" style="border-top: 1px solid #eee;">
          <div>月卡价格</div>
          <div>{{companylist.month_price}}</div>
        </div>
        <div class="ToConfigure">
          <div>月卡原价</div>
          <div>{{companylist.month_line_price}}</div>
        </div>
        <div class="ToConfigure">
          <div>季卡价格</div>
          <div>{{companylist.season_price}}</div>
        </div>
        <div class="ToConfigure">
          <div>年卡价格</div>
          <div>{{companylist.year_price}}</div>
        </div>
        <div class="ToConfigure">
          <div>年卡原价</div>
          <div>{{companylist.year_line_price}}</div>
        </div>
        <div class="ToConfigure">
          <div>月卡简介</div>
          <div>{{companylist.month_intro}}</div>
        </div>
        <div class="ToConfigure">
          <div>季卡简介</div>
          <div>{{companylist.season_intro}}</div>
        </div>
        <div class="ToConfigure">
          <div>年卡简介</div>
          <div>{{companylist.year_intro}}</div>
        </div>
        <div class="ToConfigure">
          <div>企业会员卡简介</div>
          <div>{{companylist.intro}}</div>
        </div>
        <div class="ToConfigure">
          <div>详情</div>
          <div class="details">{{companylist.content}}</div>
        </div>
      </div>
      <div v-if="isShow == 4">
        <div class="ToConfigure" style="border-top: 1px solid #eee;">
          <div>优选服务</div>
          <div class="details">{{infolist.service}}</div>
        </div>
        <div class="ToConfigure">
          <div>急速退款</div>
          <div class="details">{{infolist.refund}}</div>
        </div>
        <div class="ToConfigure">
          <div>推荐打折</div>
          <div class="details">{{infolist.discount}}</div>
        </div>
        <div class="ToConfigure">
          <div>合作返点</div>
          <div class="details">{{infolist.commission}}</div>
        </div>
      </div>
      <div v-if="isShow == 5">
        <div class="ToConfigure" style="border-top: 1px solid #eee;">
          <div>发现页标题一</div>
          <div>{{discoverlist.one}}</div>
        </div>
        <div class="ToConfigure">
          <div>发现页标题二</div>
          <div>{{discoverlist.two}}</div>
        </div>
        <div class="ToConfigure">
          <div>发现页标题三</div>
          <div>{{discoverlist.three}}</div>
        </div>
        <div class="ToConfigure">
          <div>发现页标题四</div>
          <div>{{discoverlist.four}}</div>
        </div>
        <div class="ToConfigure">
          <div>发现页标题五</div>
          <div>{{discoverlist.five}}</div>
        </div>
      </div>
    </div>


    <div class="show" v-if="editPanel">
      <div class="rightPanel-background"/>
      <div class="EditNew">
        <div class="EditNewTop">
          <div>{{titleMath}}</div>
          <div @click="editPanel = false"><i class="el-icon-close"></i></div>
        </div>
        <div class="headNavs" style="padding:0 26px">
          <el-menu
            :default-active="acndex"
            class="el-menu-demo"
            mode="horizontal"
            @select="handleSelect2">
            <el-menu-item index="1">魔豆会员</el-menu-item>
            <el-menu-item index="2">魔豆合伙人</el-menu-item>
            <el-menu-item index="3">企业会员</el-menu-item>
            <el-menu-item index="4">会员权益</el-menu-item>
            <el-menu-item index="5">发现页</el-menu-item>
          </el-menu>
        </div>
        <el-form :model="Row" :rules="rules" ref="ruleFormRef">
          <div class="EditNewForm" v-if="is_info == 1">
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>会员卡价格</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="vip.threshold" placeholder="请填写名称"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>会员卡原价</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="vip.intro" placeholder="请填写名称"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>消费门槛（元）</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="vip.price" placeholder="请填写名称"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>简介</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="vip.line_price" placeholder="请填写名称"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>详情</div>
              <div class="EditNewFormItemIpt">
                <el-input type="textarea" v-model="vip.content"></el-input>
              </div>
            </el-form-item>
          </div>
          <div class="EditNewForm" v-if="is_info == 2">
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>消费门槛（元）</div>
              <div class="EditNewFormItemIpt">
                <el-input placeholder="请填写名称" v-model="partner.threshold"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>简介</div>
              <div class="EditNewFormItemIpt">
                <el-input type="textarea" v-model="partner.intro" ></el-input>
              </div>
            </el-form-item>
          </div>
          <div class="EditNewForm" v-if="is_info == 3">
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>月卡价格</div>
              <div class="EditNewFormItemIpt">
                <el-input type="number"  placeholder="请填写名称" v-model="company.month_price"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>月卡原价</div>
              <div class="EditNewFormItemIpt">
                <el-input type="number"  placeholder="请填写名称" v-model="company.month_line_price"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>季卡价格</div>
              <div class="EditNewFormItemIpt">
                <el-input type="number"  placeholder="请填写名称" v-model="company.season_price"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>季卡原价</div>
              <div class="EditNewFormItemIpt">
                <el-input type="number"  placeholder="请填写名称" v-model="company.season_line_price"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>年卡价格</div>
              <div class="EditNewFormItemIpt">
                <el-input type="number"  placeholder="请填写名称" v-model="company.year_price"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>年卡原价</div>
              <div class="EditNewFormItemIpt">
                <el-input type="number"  placeholder="请填写名称" v-model="company.year_line_price"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>月卡简介</div>
              <div class="EditNewFormItemIpt">
                <el-input placeholder="请填写名称" v-model="company.month_intro"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>季卡简介</div>
              <div class="EditNewFormItemIpt">
                <el-input placeholder="请填写名称" v-model="company.season_intro"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>年卡简介</div>
              <div class="EditNewFormItemIpt">
                <el-input placeholder="请填写名称" v-model="company.year_intro"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>企业会员卡简介</div>
              <div class="EditNewFormItemIpt">
                <el-input placeholder="请填写名称" v-model="company.intro"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>详情</div>
              <div class="EditNewFormItemIpt">
                <el-input type="textarea" v-model="company.content" ></el-input>
              </div>
            </el-form-item>
          </div>
          <div class="EditNewForm" v-if="is_info == 4">
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>优选服务</div>
              <div class="EditNewFormItemIpt">
                <el-input type="textarea" v-model="info.service" ></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>急速退款</div>
              <div class="EditNewFormItemIpt">
                <el-input type="textarea" v-model="info.refund" ></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>推荐打折</div>
              <div class="EditNewFormItemIpt">
                <el-input type="textarea" v-model="info.discount" ></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>合作返点</div>
              <div class="EditNewFormItemIpt">
                <el-input type="textarea" v-model="info.commission" ></el-input>
              </div>
            </el-form-item>
          </div>
          <div class="EditNewForm" v-if="is_info == 5">
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>发现页标题一</div>
              <div class="EditNewFormItemIpt">
                <el-input placeholder="请填写名称" v-model="discover.one"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>发现页标题二</div>
              <div class="EditNewFormItemIpt">
                <el-input placeholder="请填写名称" v-model="discover.two"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>发现页标题三</div>
              <div class="EditNewFormItemIpt">
                <el-input placeholder="请填写名称" v-model="discover.three"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>发现页标题四</div>
              <div class="EditNewFormItemIpt">
                <el-input placeholder="请填写名称" v-model="discover.four"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>发现页标题五</div>
              <div class="EditNewFormItemIpt">
                <el-input placeholder="请填写名称" v-model="discover.five"></el-input>
              </div>
            </el-form-item>
          </div>

          <el-form-item class="EditNewFormBtns">
            <div class="FormBtns">
              <div class="FormBtnsQuery" @click="BtnEdit()">保存</div>
              <div class="FormBtnsClose" @click="editPanel = false">取消</div>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>

  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: "index",
  data() {
    return {
      groupList:[],//总数据 
      memberConfig:[],
      viplist:{},
      partnerlist:{},
      companylist:{},
      infolist:{},
      discoverlist:{},
      //魔豆会员
      vip:{
        threshold:'',
        intro:'',
        price:'',
        line_price:'',
        content:'',
      },
     //魔豆合伙人
      partner:{
        threshold:'',
        intro:''
      },
      //企业会员
      company:{
        month_price:'',
        month_line_price:'',
        season_price:'',
        season_line_price:'',
        year_price:'',
        year_line_price:'',
        season_intro:'',
        year_intro:'',
        intro:'',
        content:''
      },
      //会员权益
      info:{
        service:'',
        refund:'',
        discount:'',
        commission:'',
      },
      //发现页
      discover:{
        one:'',
        two:'',
        three:'',
        four:'',
        five:'',
      }, 
      isShow:1,
      is_info:1,
      activeIndex:1,
      acndex:1,
      titleMath:'',
      editPanel:false
    }
  },
  mounted() {
    this.loadTable();
  },
  methods: {
    loadTable() {
      var that = this
      axios({
        method: 'get',
        url: '/admin/member/config/index',
      }).then(function (res) {
        that.groupList = res.data.data.groupList
        that.viplist =  res.data.data.memberConfig.vip
        that.partnerlist =  res.data.data.memberConfig.partner
        that.companylist =  res.data.data.memberConfig.company
        that.infolist =  res.data.data.memberConfig.info
        that.discoverlist =  res.data.data.memberConfig.discover
      })
    },
    //导航选择
    handleSelect_top(e){
      var that = this
      that.activeIndex = e
      if(e == 1){
        that.isShow = 1
      } else if(e == 2){
        that.isShow = 2
      }else if(e == 3){
        that.isShow = 3
      }else if(e == 4){
        that.isShow = 4
      }else if(e == 5){
        that.isShow = 5
      }
    },
    handleSelect2(e){
      var that = this
      that.acndex = e
      if(e == 1){
        that.is_info = 1
      } else if(e == 2){
        that.is_info = 2
      }else if(e == 3){
        that.is_info = 3
      }else if(e == 4){
        that.is_info = 4
      }else if(e == 5){
        that.is_info = 5
      }
    },
    headerEdit(){
      var that = this 
      that.editPanel = true
      that.titleMath = '编辑'
      that.acndex = 1
      axios({
        method: 'get',
        url: '/admin/member/config/index',
      }).then(function (res) {
        that.vip =  res.data.data.memberConfig.vip
        that.partner =  res.data.data.memberConfig.partner
        that.company =  res.data.data.memberConfig.company
        that.info =  res.data.data.memberConfig.info
        that.discover =  res.data.data.memberConfig.discover
      })
    },
    //编辑
    BtnEdit(){
      var that = this
      let row = {}
      row['vip'] = that.vip
      row['partner'] = that.partner
      row['company'] = that.company
      row['info'] = that.info
      row['discover'] = that.discover
      axios({
        method: 'post',
        url: '/admin/member/config/edit',
        data:{
         row 
        }
      }).then(function(res){
         that.$message.success(res.data.msg);
         that.editPanel = false
        that.loadTable();
      })
    },
  }

}
</script>

<style scoped>

</style>